import React, { useState, useContext, useEffect, useCallback } from 'react';
import '../StudentAttendanceRecord/StudentAttendanceRecordStyle.scss';
import blue_square from '../../Assets/Images/blue_square.png';
import blue_circle from '../../Assets/Images/blue_circle.png';
import blue_hexagon from '../../Assets/Images/blue_hexagon.png';
import blue_gear from '../../Assets/Images/blue_gear.png';
import green_circle from '../../Assets/Images/green_circle.png';
import green_gear from '../../Assets/Images/green_gear.png';
import green_hexagon from '../../Assets/Images/green_hexagon.png';
import green_rectangle from '../../Assets/Images/green_rectangle.png';
import PrimaryButton from '../Common/PrimaryButton';
import CommonTextInput from '../Common/CommonTextInput';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { CounterContext } from '../../App';
import { ApiCall } from '../../Utils/ApiComponets/ApiCall';
import { LuLoader2 } from "react-icons/lu";


const StudentAttendanceRecord = (props) => {
    const { handleChangeCount, selecteDivisonDate,
        attendanceStandardSelectedLabel, attendanceDivSelectedLabel, studentName, rollNo, userID
    } = useContext(CounterContext);
    const [isEditButtonPressed, setisEditButtonPressed] = useState(false);
    const Status = ['Present', 'Absent'];
    const [selectStatus, setselectStatus] = useState('Present');

    const [studentComment, setstudentComment] = useState('');
    const [studentCommentError, setstudentCommentError] = useState('');
    const [isSubmitButtonClicked, setisSubmitButtonClicked] = useState(false);

    const [studentsAttendaceResponse, setStudentsAttendanceResponse] = useState([]);
    const [studentListLoader, setStudentListLoader] = useState(false);
    const [attendanceId, setattendanceId] = useState(0);
    const [mobileNumber, setmobileNumber] = useState(0);

    let todaysDate = new Date();
    let todaysday = todaysDate.getDate().toString().padStart(2, '0'); // Ensures 2-digit format
    let todaysmonth = (todaysDate.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
    let todaysyear = todaysDate.getFullYear();

    let todaysformattedDate = `${todaysday}/${todaysmonth}/${todaysyear}`;


    const [extractedDate, setextractedDate] = useState(selecteDivisonDate.charAt(0) + selecteDivisonDate.charAt(1) || todaysday)
    const [extractedMonth, setextractedMonth] = useState(selecteDivisonDate.charAt(3) + selecteDivisonDate.charAt(4) || todaysmonth);

    const [extractedYear, setextractedYear] = useState(selecteDivisonDate.slice(6) || todaysyear);

    let extractedCompleteDate = `${extractedMonth}/${extractedDate}/${extractedYear}`

    const [date, setDate] = useState(extractedCompleteDate);

    const formatteddate = new Date(date);

    const monthIndex = formatteddate.getMonth() + 1;

    const day = String(formatteddate.getDate()).padStart(2, '0'); // Ensures two digits
    const month = String(formatteddate.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed, so add 1
    const year = formatteddate.getFullYear();

    const formattedDisplayDate = `${day}/${month}/${year}`;

    const [isAttendanceNotRecorded, setisAttendanceNotRecorded] = useState(false);


    const tileClassName = ({ date }) => {
        const datedata = new Date(date);
        const formattedDate = datedata.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });

        const highlighted = studentsAttendaceResponse.find((item) => item.date === formattedDate);
        if (highlighted) {
            // const statusClass = highlighted.status === 1 ? 'highlight-present' : 'highlight-absent';
            const statusClass = highlighted.status === 1
                ? 'highlight-present'
                : highlighted.status === 2
                    ? 'highlight-NA'
                    : 'highlight-absent';

            if (highlighted.status === 2) {
                setisAttendanceNotRecorded(true);
            }

            const commentClass = highlighted.comment ? 'highlight-comment' : '';
            // if (highlighted.status == 2) {
            //     return ``;
            // }
            return `${statusClass} ${commentClass}`;
        }

        // return 'highlight-flag';
    };



    const handle_student_attendance_record_edit_button = () => {
        setisEditButtonPressed(!isEditButtonPressed);
    };

    const handleStatusChange = (event) => {
        if (!isEditButtonPressed) {
            return;
        }
        setselectStatus(event.target.value);
    };

    const handleSubmitButtonClicked = () => {
        triggerUpdateStatus();
        setisSubmitButtonClicked(true);
    }

    const [attendanceData, setattendanceData] = useState([
        { week: 'Week 1', present: "-", absent: "-" },
        { week: 'Week 2', present: "-", absent: "-" },
        { week: 'Week 3', present: "-", absent: "-" },
        { week: 'Week 4', present: "-", absent: "-" },
    ])

    const handle_student_attendance_record_back_button = () => {
        handleChangeCount(1);
    }


    useEffect(() => {
        const fetchAttendanceData = async () => {
            await triggerCalendarOverAll(extractedMonth, extractedYear);
        };
        fetchAttendanceData();
    }, [])

    useEffect(() => {
        triggerUserOverAll()
    }, [date])


    const triggerCalendarOverAll = (monthNumber, year) => {
        return new Promise(async (res, rej) => {
            try {
                // const sorted = defaultCall || sort == 'atoz' ? 'atoz' : sort;
                setStudentListLoader(true);
                let generatedApiUrl = `attendance/getusermonthwise?userId=${userID || props.userId}&month=${monthNumber}&year=${year}`;
                const response = await ApiCall(generatedApiUrl, null, 'get');
                console.log(response, "response");

                setStudentsAttendanceResponse(response.responseJson.studentsAttendanceResponse);
                setStudentListLoader(false);
                res();
            } catch (error) {
                setStudentListLoader(false);
                console.error("Failed to trigger API", error);
                rej(error);
            }
        });
    };

    const triggerUpdateStatus = () => {
        return new Promise(async (res, rej) => {
            try {
                let body = {
                    "attendanceDetails": {
                        "attendanceId": attendanceId.toString(),
                        "status": selectStatus == "Absent" ? 0 : 1, // 1 for present
                        "comment": studentComment
                    }
                }
                let generatedApiUrl = `attendance/update?userId=${userID || props.userId}`;
                const response = await ApiCall(generatedApiUrl, body, 'put');
                setisEditButtonPressed(!isEditButtonPressed);
                await triggerCalendarOverAll(extractedMonth, extractedYear);

                res();
            } catch (error) {
                console.error("Failed to trigger API", error, error.message);
                rej(error);
            }
        });
    };


    useEffect(() => {
        // Check if today's date has no attendance data and update state
        const today = new Date().toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });

        const highlighted = studentsAttendaceResponse.find((item) => item.date === formattedDisplayDate);

        setselectStatus(highlighted?.status === 1 ? "Present" : "Absent");
        setstudentComment(highlighted?.comment);
        setattendanceId(highlighted?._id);
        // If today's date has no data, set the state as 'not recorded'
        if (!highlighted) {
            console.log("ia m geeitn in true for sAttendanceNotRecorded");

            setisAttendanceNotRecorded(true);
        } else {
            setisAttendanceNotRecorded(false);
        }
    }, [studentsAttendaceResponse]);

    const [formattedDate, setFormattedDate] = useState(null);

    const handleOnClickDay = (value) => {

        setisEditButtonPressed(false);
        const datedata = new Date(value);
        const formattedDate = datedata.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });

        const formattedDefaultDate = formatDateToMMDDYYYY(value);
        setDate(formattedDefaultDate);

        setFormattedDate(formattedDate);
        setisAttendanceNotRecorded(false);  // Reset attendance status flag

        const highlighted = studentsAttendaceResponse.find((item) => item.date === formattedDate);

        setselectStatus(highlighted?.status === 1 ? "Present" : "Absent");
        setstudentComment(highlighted?.comment);
        setattendanceId(highlighted?._id);

        if (!highlighted) {
            setisAttendanceNotRecorded(true);
        }
    };

    // useEffect(() => {
    //     if (formattedDate) {
    //         console.log(formattedDate, "formattedDate in useEffect ", studentsAttendaceResponse);

    //         // Find attendance data for the selected date
    //         const highlighted = studentsAttendaceResponse.find((item) => item.date === formattedDate);
    //         console.log(highlighted, "highlighted in useeffect >>>");

    //         // Update the status and comments based on the selected date's attendance data
    //         setselectStatus(highlighted?.status === 1 ? "Present" : "Absent");
    //         setstudentComment(highlighted?.comment);
    //         setattendanceId(highlighted?._id);
    //         // If no data is found for the selected date, mark attendance as not recorded
    //         if (!highlighted) {
    //             console.log("getting true here ");

    //             setisAttendanceNotRecorded(true);
    //         } else {
    //             setisAttendanceNotRecorded(false);

    //         }
    //     }
    // }, [formattedDate, studentsAttendaceResponse]);  // Effect triggers on formattedDate or attendance data change


    const months = [
        { id: 1, name: 'Jan' },
        { id: 2, name: 'Feb' },
        { id: 3, name: 'Mar' },
        { id: 4, name: 'Apr' },
        { id: 5, name: 'May' },
        { id: 6, name: 'Jun' },
        { id: 7, name: 'Jul' },
        { id: 8, name: 'Aug' },
        { id: 9, name: 'Sep' },
        { id: 10, name: 'Oct' },
        { id: 11, name: 'Nov' },
        { id: 12, name: 'Dec' }
    ];


    let filteredMonth = months.filter((e) => e.id == monthIndex ? e.name : null);
    let monthName = filteredMonth[0]?.name;

    const triggerUserOverAll = () => {
        return new Promise(async (res, rej) => {
            try {
                // const sorted = defaultCall || sort == 'atoz' ? 'atoz' : sort;
                setStudentListLoader(true);
                let generatedApiUrl = `attendance/getuseroverall?userId=${userID || props.userId}&month=${monthIndex}&year=${year}`;
                const response = await ApiCall(generatedApiUrl, null, 'get');
                setmobileNumber(response.responseJson.studentDetails.mobileNumber);
                setattendanceData(response.responseJson);
                setStudentListLoader(false);
                res();
            } catch (error) {
                setStudentListLoader(false);
                console.error("Failed to trigger API", error);
                rej(error);
            }
        });
    };

    const formatDateToMMDDYYYY = (date) => {
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-based
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
    };

    const handleDateSelect = (date) => {
        const formattedDefaultDate = formatDateToMMDDYYYY(date);
        setDate(formattedDefaultDate);
    }


    const handleMonthChange = async (activeStartDate) => {
        setisAttendanceNotRecorded(false);

        const monthNumber = activeStartDate.getMonth() + 1; // 0-based month index
        const year = activeStartDate.getFullYear();

        const defaultDate = new Date(year, monthNumber - 1, 1);
        const formattedDefaultDate = formatDateToMMDDYYYY(defaultDate);
        console.log(formattedDefaultDate, "formattedDefaultDate");

        setDate(formattedDefaultDate);

        setextractedMonth(monthNumber);
        setextractedYear(year);
        // setTimeout(() => {
        await triggerCalendarOverAll(monthNumber, year);
        // }, 2000);
    };



    return (
        <div className="student-attendance-record-parent-container">
            {/* {
                studentListLoader ?
                    <div className={`dashboard-student-list-loader`}>
                        <div>
                            <LuLoader2 className={`spinner`} />
                            <p>Fetching student records. Please wait...</p>
                        </div>
                    </div> : */}
            <>
                {
                    props.isAdmissionTabsSection ? null :

                        <div className="student-attendance-record-background-shapes-container">
                            <img className='student-attendance-record-blue_hexagon' src={blue_hexagon} alt="blue_hexagon" />
                            <img className='student-attendance-record-green_hexagon' src={green_hexagon} alt="green_hexagon" />
                            <img className='student-attendance-record-blue_gear' src={blue_gear} alt="blue_gear" />
                            <img className='student-attendance-record-green_gear' src={green_gear} alt="green_gear" />
                            <img className='student-attendance-record-green_rectangle' src={green_rectangle} alt="green_rectangle" />
                            <img className='student-attendance-record-blue_square' src={blue_square} alt="blue_square" />
                            <img className='student-attendance-record-green_circle' src={green_circle} alt="green_circle" />
                            <img className='student-attendance-record-blue_circle' src={blue_circle} alt="blue_circle" />
                        </div>
                }

                <div className={props.isAdmissionTabsSection ? "student-attendance-record-subscription-content-parent-secondary" : "student-attendance-record-subscription-content-parent"}>
                    <div className="student-attendance-record-left-container">
                        <div className="student-attendance-record-left-container-personal-details">
                            <div className="student-attendance-record-left-container-personal-details-name-container">
                                <h3>Name :</h3>
                                <p> {studentName || props.name}</p>
                            </div>
                            <div className="student-attendance-record-left-container-personal-details-mobile-container">
                                <h3>Mobile No. :</h3>
                                <p>+91{props.mobileNumber ? props.mobileNumber : mobileNumber}</p>
                            </div>
                            <div className="student-attendance-record-left-container-personal-details-std-div-container">
                                <div className="student-attendance-record-left-container-personal-details-std-container">
                                    <h3>Std. :</h3>
                                    <p>{attendanceStandardSelectedLabel || props.standardSelectedValue}</p>
                                </div>
                                <div className="student-attendance-record-left-container-personal-details-div-container">
                                    <h3>Div. :</h3>
                                    <p>{attendanceDivSelectedLabel || props.divisionSelectedValue}</p>
                                </div>
                            </div>
                            <div className="student-attendance-record-left-container-personal-details-roll-no-container">
                                <h3>Roll No. :</h3>
                                <p>{rollNo || props.rollNo}</p>
                            </div>
                        </div>
                        {
                            studentListLoader ?
                                <div className={`dashboard-student-list-loader`}>
                                    <div>
                                        <LuLoader2 className={`spinner`} />
                                        <p>Fetching student records. Please wait...</p>
                                    </div>
                                </div> :
                                <div className='student-attendance-record-calendar'>
                                    <Calendar
                                        onActiveStartDateChange={({ activeStartDate }) => handleMonthChange(activeStartDate)}
                                        onChange={handleDateSelect}
                                        value={date}
                                        tileClassName={tileClassName}
                                        onClickDay={(value) => handleOnClickDay(value)}
                                    />
                                    <div className="calendar-legends">
                                        <div className="legend-item present-container">
                                            <div className="legend-box present-box"></div>
                                            <h3>Present</h3>
                                        </div>
                                        <div className="legend-item absent-container">
                                            <div className="legend-box absent-box"></div>
                                            <h3>Absent</h3>
                                        </div>
                                        <div className="legend-item present-container">
                                            <div className="legend-box active-box"></div>
                                            <h3>Selected</h3>
                                        </div>
                                        <div className="legend-item present-container">
                                            <div className="legend-box NA-box"></div>
                                            <h3>Not Applicable</h3>
                                        </div>
                                        {/* <div className="legend-item absent-container">
                                            <div className="legend-box current-date-box"></div>
                                            <h3>Today</h3>
                                        </div> */}
                                    </div>

                                </div>
                        }
                        {
                            props.isAdmissionTabsSection ?
                                null :

                                <div className='student-attendance-record-back-button-container'>
                                    <PrimaryButton
                                        buttonText={"Back"}
                                        onClick={() => handle_student_attendance_record_back_button()}
                                        className={"student-attendance-record-edit-button"} />
                                </div>
                        }
                    </div>
                    <div className="student-attendance-record-right-container">
                        {
                            isAttendanceNotRecorded ?
                                null
                                :
                                <div className='student-attendance-record-edit-button-container'>
                                    <PrimaryButton
                                        buttonText={!isEditButtonPressed ? "Edit" : "Cancel"}
                                        onClick={() => handle_student_attendance_record_edit_button()}
                                        className={"student-attendance-record-edit-button"} />
                                </div>
                        }
                        {
                            studentListLoader ?
                                <div className={`dashboard-student-list-loader`}>
                                    <div>
                                        <LuLoader2 className={`spinner`} />
                                        <p>Fetching student records. Please wait...</p>
                                    </div>
                                </div> :
                                <div className="student-attendance-record-date-status-comment-container">
                                    <div className="student-attendance-record-date-container">
                                        <h3>Date :</h3>
                                        <p>{formattedDisplayDate}</p>
                                    </div>
                                    {isAttendanceNotRecorded ?
                                        <p className='after_submit_para'>
                                            Attendance is not recorded for this date. Please record attendance for all students of this division on the selected date.
                                        </p>
                                        :
                                        <>
                                            <div className="student-attendance-record-status-container">
                                                <div>
                                                    <h4 style={{ color: !isEditButtonPressed ? 'lightgrey' : 'black' }}>Status :</h4>
                                                </div>
                                                <div className="status-wrapper">
                                                    {Status.map((item, index) => (
                                                        <div key={index}>
                                                            <input
                                                                type="radio"
                                                                id={`Status-${item}`}
                                                                name="Status"
                                                                value={item}
                                                                // checked={selectStatus == 1 ? "Present" === item : "Absent" === item}
                                                                checked={selectStatus === item}
                                                                onChange={handleStatusChange}
                                                                disabled={!isEditButtonPressed}
                                                                style={{ transform: 'scale(1.5)', marginRight: "0.5vw", backgroundColor: !isEditButtonPressed ? 'lightgrey' : 'white' }} // Change background color conditionally
                                                            />
                                                            <label
                                                                style={{ transform: 'scale(1.5)', marginRight: "0.5vw", color: !isEditButtonPressed ? 'lightgrey' : 'black' }} // Change background color conditionally

                                                                htmlFor={`Status-${item}`}>{item}</label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="student-attendance-record-comment-container">
                                                <CommonTextInput
                                                    label={"Comment"}
                                                    inputValue={studentComment}
                                                    setInputValue={setstudentComment}
                                                    error={studentCommentError}
                                                    disabled={!isEditButtonPressed}
                                                    maxLength="254"
                                                    className={!isEditButtonPressed ? 'disabled-input' : ''}
                                                    disableFocusByValue={true}
                                                />
                                            </div>
                                        </>}

                                </div>
                        }
                        {isEditButtonPressed ?
                            <div className='student-attendance-record-submit-button-container'>
                                <PrimaryButton
                                    buttonText={"Submit"}
                                    onClick={() => handleSubmitButtonClicked()}
                                    className={"student-attendance-record-submit-button"} />
                            </div>

                            : null}

                        <table>
                            <thead>
                                <tr>
                                    <th>Weeks</th>
                                    <th>Present</th>
                                    <th>Absent</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {attendanceData?.weeklyData?.map((item, index) => (

                                    <tr style={{ fontWeight: index > 3 ? '700' : 'normal' }} key={index}>
                                        <td>{item.week}</td>
                                        <td>{item.present}</td>
                                        <td>{item.absent}</td>
                                        <td>{item.workingDays}</td>
                                    </tr>
                                ))}
                                <tr style={{ fontWeight: '700' }}>
                                    <td>MTD({monthName})</td>
                                    <td>{attendanceData?.mtd?.present}</td>
                                    <td>{attendanceData?.mtd?.absent}</td>
                                    <td>{attendanceData?.mtd?.workingDays}</td>
                                </tr>
                                <tr style={{ fontWeight: '700' }}>
                                    <td>YTD</td>
                                    <td>{attendanceData?.ytd?.present}</td>
                                    <td>{attendanceData?.ytd?.absent}</td>
                                    <td>{attendanceData?.ytd?.workingDays}</td>
                                </tr>
                                <tr style={{ fontWeight: '700' }}>
                                    <td>Percentage</td>
                                    <td>{attendanceData?.attendancePercentage?.presentPercentage}</td>
                                    <td>{attendanceData?.attendancePercentage?.absentPercentage}</td>
                                    <td>{attendanceData?.attendancePercentage?.totalPercentage}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className='student-attendance-record-back-button-container-mobile'>
                            <PrimaryButton
                                buttonText={"Back"}
                                onClick={() => handle_student_attendance_record_back_button()}
                                className={"student-attendance-record-edit-button"} />
                        </div>
                    </div>
                </div>
            </>
            {/* } */}
        </div >
    );
}

export default StudentAttendanceRecord;
